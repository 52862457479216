@tailwind base;
@tailwind components;
@tailwind utilities;

/* Base styles */
:root {
  --color-primary: #4F46E5;
  --color-primary-dark: #4338CA;
  --color-secondary: #1E1B4B;
  --color-accent: #7C3AED;
  --color-accent-light: #9F7AEA;
  --color-background: #0F172A;
  --color-surface: #1E293B;
  --color-text: #F8FAFC;
  --color-primary-light: #818cf8;
  --color-accent-light: #a78bfa;

  /* Bounding box colors - remove fill colors and adjust opacity */
  --bbox-detected-stroke: rgba(79, 70, 229, 0.9);    /* primary */
  --bbox-suspected-stroke: rgba(124, 58, 237, 0.9);  /* accent */
}

/* Use system fonts with a modern stack */
body {
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  background-color: var(--color-background);
  color: var(--color-text);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Modern headings with system fonts */
h1, h2, h3, h4, h5, h6 {
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont;
  letter-spacing: -0.025em;
}

/* Futuristic table styles */
table {
  border-collapse: separate;
  border-spacing: 0;
  width: 100%;
}

th {
  background-color: var(--color-surface);
  font-weight: 600;
  letter-spacing: 0.05em;
  text-transform: uppercase;
}

td {
  background-color: rgba(30, 41, 59, 0.3);
  transition: background-color 0.2s ease;
}

tr:hover td {
  background-color: rgba(30, 41, 59, 0.5);
}

/* Futuristic tag styles */
.tag {
  @apply px-2.5 py-1 rounded-md text-xs font-medium;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: all 0.2s ease;
  letter-spacing: 0.025em;
}

.tag.detected {
  background: linear-gradient(135deg, var(--color-primary), var(--color-primary-dark));
}

.tag.suspected {
  background: linear-gradient(135deg, var(--color-accent-light), var(--color-accent));
}

.tag:hover {
  transform: translateY(-1px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Glowing effects */
.glow {
  box-shadow: 0 0 10px rgba(79, 70, 229, 0.3);
}

.glow:hover {
  box-shadow: 0 0 20px rgba(79, 70, 229, 0.5);
}

/* Modern button styles */
.btn-modern {
  @apply font-medium tracking-wide transition-all duration-200;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

/* Glass effect */
.glass {
  @apply backdrop-blur-sm bg-opacity-50;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
              0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

/* Hide scrollbar but keep functionality */
.hide-scrollbar {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Shine effect */
@keyframes shine {
  0% {
    background-position: 200% center;
  }
  100% {
    background-position: -200% center;
  }
}

.shine-effect::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.2) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  background-size: 200% auto;
  animation: shine 3s linear infinite;
}

/* Bounding box canvas styles */
.bbox-detected {
  --bbox-stroke: var(--bbox-detected-stroke);
  --bbox-fill: var(--bbox-detected-fill);
}

.bbox-suspected {
  --bbox-stroke: var(--bbox-suspected-stroke);
  --bbox-fill: var(--bbox-suspected-fill);
}

/* Label styles */
.bbox-label {
  font-size: 12px;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont;
  padding: 4px;
  height: 20px;
  background-color: var(--bbox-stroke);
  color: white;
}

/* Scrollbar styling */
.slice-scrollbar {
  @apply absolute bottom-0 left-0 right-0 flex items-center px-4 py-2 bg-gray-900/95 backdrop-blur-md border-t border-gray-800/50;
}

.slice-scrollbar-text {
  @apply text-xs font-medium text-gray-400 mr-3 w-16;
}

/* Range input styling */
.range-input {
  @apply w-full h-1 bg-gray-800 rounded-full appearance-none cursor-pointer;
}

/* Webkit (Chrome, Safari, Edge) */
.range-input::-webkit-slider-thumb {
  @apply appearance-none w-3 h-3 rounded-full bg-primary border-2 border-gray-900 transition-all;
}

.range-input::-webkit-slider-thumb:hover {
  @apply bg-primary/80;
}

/* Firefox */
.range-input::-moz-range-thumb {
  @apply w-3 h-3 rounded-full bg-primary border-2 border-gray-900 transition-all;
}

.range-input::-moz-range-thumb:hover {
  @apply bg-primary/80;
}

/* Hide default range track in Firefox */
.range-input::-moz-range-track {
  @apply bg-gray-800 h-1 rounded-full;
}

/* Microsoft Edge and IE */
.range-input::-ms-thumb {
  @apply w-3 h-3 rounded-full bg-primary border-2 border-gray-900 transition-all;
}

.range-input::-ms-thumb:hover {
  @apply bg-primary/80;
}

/* Hide default focus styles */
.range-input:focus {
  @apply outline-none;
}

/* Vertical range input styling */
.range-input-vertical {
  @apply bg-gray-800 rounded-full appearance-none cursor-pointer;
  writing-mode: vertical-lr;
  direction: rtl;
  width: 1rem !important;
  height: 100%;
  padding: 0.5rem 0;
}

/* Webkit (Chrome, Safari, Edge) */
.range-input-vertical::-webkit-slider-thumb {
  @apply appearance-none w-3 h-3 rounded-full bg-primary border-2 border-gray-900 transition-all;
}

.range-input-vertical::-webkit-slider-thumb:hover {
  @apply bg-primary/80;
}

/* Firefox */
.range-input-vertical::-moz-range-thumb {
  @apply w-3 h-3 rounded-full bg-primary border-2 border-gray-900 transition-all;
}

.range-input-vertical::-moz-range-thumb:hover {
  @apply bg-primary/80;
}

/* Hide default range track in Firefox */
.range-input-vertical::-moz-range-track {
  @apply bg-gray-800 rounded-full;
}

/* Microsoft Edge and IE */
.range-input-vertical::-ms-thumb {
  @apply w-3 h-3 rounded-full bg-primary border-2 border-gray-900 transition-all;
}

.range-input-vertical::-ms-thumb:hover {
  @apply bg-primary/80;
}

/* Hide default focus styles */
.range-input-vertical:focus {
  @apply outline-none;
}

/* Add these styles for the vertical range input */
.range-vertical {
  -webkit-appearance: none;
  appearance: none;
  writing-mode: vertical-lr;
  direction: rtl;
  width: 1rem !important;
  height: 100%;
  padding: 0.5rem 0;
  margin: 0;
  background: transparent;
}

/* Thumb styles */
.range-vertical::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 12px;
  height: 12px;
  background: var(--thumb-color, var(--color-primary));
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.2s ease;
}

.range-vertical::-moz-range-thumb {
  width: 12px;
  height: 12px;
  background: var(--thumb-color, var(--color-primary));
  border: none;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.2s ease;
}

/* Track styles */
.range-vertical::-webkit-slider-runnable-track {
  width: 100%;
  height: 100%;
  background: var(--track-color, var(--color-surface));
  cursor: pointer;
  border-radius: 6px;
}

.range-vertical::-moz-range-track {
  width: 100%;
  height: 100%;
  background: var(--track-color, var(--color-surface));
  cursor: pointer;
  border-radius: 6px;
}

/* Make the entire slider area clickable */
.range-vertical::-webkit-slider-container {
  height: 100%;
  cursor: pointer;
}

.range-vertical::-moz-range-track {
  height: 100%;
  cursor: pointer;
}

/* Hover states */
.range-vertical:hover::-webkit-slider-thumb {
  background: var(--thumb-hover-color);
}

.range-vertical:hover::-moz-range-thumb {
  background: var(--thumb-hover-color);
}

/* Active states */
.range-vertical:active::-webkit-slider-runnable-track {
  background: var(--track-active-color);
}

.range-vertical:active::-moz-range-track {
  background: var(--track-active-color);
}

/* Add these styles to your index.css */

/* Custom scrollbar styles */
.overflow-y-auto {
  scrollbar-width: thin;
  scrollbar-color: rgba(75, 85, 99, 0.3) transparent;
}

.overflow-y-auto::-webkit-scrollbar {
  width: 4px;
}

.overflow-y-auto::-webkit-scrollbar-track {
  background: transparent;
}

.overflow-y-auto::-webkit-scrollbar-thumb {
  background-color: rgba(75, 85, 99, 0.3);
  border-radius: 2px;
}

.overflow-y-auto::-webkit-scrollbar-thumb:hover {
  background-color: rgba(75, 85, 99, 0.5);
}

/* Ensure smooth scrolling */
* {
  scroll-behavior: smooth;
}

/* Update the container styles if needed */
.range-vertical-container {
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Update the report finding styles */
.report-finding {
  @apply cursor-pointer relative rounded-lg;
  @apply transition-all duration-200 ease-in-out;
  @apply bg-gradient-to-r from-accent/10 to-accent/20 border-accent/30;
  @apply border border-transparent;
  @apply hover:bg-opacity-90;
  padding: 0.75rem 1rem;
  margin-bottom: 0.5rem;
}

.report-finding-text {
  @apply text-sm text-gray-300 flex-1;
}

/* Update textarea to match */
.report-finding-textarea {
  @apply w-full bg-transparent text-sm text-gray-300;
  @apply focus:outline-none focus:ring-1 focus:ring-primary;
  resize: none;
  padding: 0;
  border: none;
  padding-right: 1.5rem;
}

/* Update selected state */
.report-finding.selected {
  @apply bg-gradient-to-r from-primary/10 to-primary/20;
  @apply border-primary/30 border;
}

/* Remove the transform and shine effects */
.report-finding:hover {
  transform: none;
}

.report-finding.selected::before {
  display: none;
}

/* Simplify the shadow */
.report-finding {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.report-finding:hover {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
}

/* Update the section container styles */
.findings-section {
  @apply bg-gray-900/95 backdrop-blur-md border border-gray-800/50 rounded-lg overflow-hidden shadow-lg;
}

/* Update the section header styles */
.findings-section-header {
  @apply p-2 bg-gray-800/50 border-b border-gray-700/50 flex items-center justify-between cursor-pointer;
}

/* Update the section content styles */
.findings-section-content {
  @apply p-2 bg-gray-800/50 max-h-[40vh] overflow-y-auto;
}

/* Add this to control the spatial groups section height */
.spatial-groups-content {
  @apply max-h-[40vh] overflow-y-auto;
}

/* Add smooth transitions for report findings */
.report-finding {
  transition: all 0.2s ease-in-out;
}

.report-finding button {
  opacity: 0;
  transition: all 0.2s ease-in-out;
}

.report-finding:hover button {
  opacity: 1;
}